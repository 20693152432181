<app-modal
  contentClasses="bg-transparent"
  [visible]="visible"
  [showHeader]="false"
  [contentRef]="content"
  [showFooter]="false"
/>
<ng-template #content>
  <div class="flex flex-col relative">
    <button class="w-full flex text-white justify-end items-center my-2 absolute" (click)="handleClosePopup()">
      <app-icon name="closeWhite" [size]="20" />
      <div class="text-xs">ปิด</div>
    </button>
    <button (click)="handleClick()">
      <object
        aria-label="Wholesale Pending FMCG Promotion"
        type="image/svg+xml"
        data="/assets/images/promotion/ws-pending-fmcg-promotion.svg"
        class="w-full pointer-events-none"
      >
        <img
          src="/assets/images/promotion/ws-pending-fmcg-promotion.png"
          alt="ws-pending-fmcg-promotion"
          class="w-full"
        />
      </object>
    </button>
  </div>
</ng-template>
