import { Component, inject } from '@angular/core'
import { IconModule } from '@shared/components/v1/icon/icon.component'
import { ModalComponent } from '@shared/components/v1/modal/modal.component'
import { CommonModule } from '@angular/common'
import { WsFmcgPendingPromotionService } from '@core/services/ws-fmcg-pending-promotion/ws-fmcg-pending-promotion.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-ws-fmcg-pending-promotion',
  standalone: true,
  imports: [CommonModule, IconModule, ModalComponent],
  templateUrl: './ws-fmcg-pending-promotion-popup.component.html',
})
export class WsFmcgPendingPromotionPopupComponent {
  router = inject(Router)
  wsFmcgPendingPromotionService = inject(WsFmcgPendingPromotionService)

  get visible() {
    return this.wsFmcgPendingPromotionService.showWsFmcgPromotionPopup
  }

  handleClosePopup() {
    this.wsFmcgPendingPromotionService.showWsFmcgPromotionPopup = false
  }

  handleClick() {
    this.router.navigateByUrl(`ws/promotion/how-to-join-fmcg-promotion`)
    this.handleClosePopup()
  }
}
